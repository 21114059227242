<template>
  <div style="min-height: 750px;">
    <page-info-website-unit v-if="d_websiteUnitData" :p_WebsiteUnitData="d_websiteUnitData"></page-info-website-unit>

<!--     <b-row style="margin-bottom: 20px;">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" md="8">
        <strong style="font-size: 30px; font-family: cursive;">{{ d_pageInfoData.page_top_caption }}</strong>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-row v-if="d_websiteUnitData">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <page-info-website-unit :p_WebsiteUnitData="d_websiteUnitData"></page-info-website-unit>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
 --><!--     <b-row v-if="d_pageInfoData">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <template v-for="(page_info, page_info_index) in d_pageInfoData.list">
          <page-info :p_pageInfo="page_info"></page-info>
          <hr>
        </template>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
 -->  </div>
</template>

<script>
import FrontendService from '@/services/frontend';
import { data as frontend_websiteunit } from '@/options/frontend_websiteunit';
/*
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
*/
import {
  default as PageInfoWebsiteUnit
} from '@/components/widgets/PageInfoWebsiteUnit';
import { version } from '@/config';
import { mapGetters } from 'vuex';
export default {
  name: 'general',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    // PageInfo,
    PageInfoWebsiteUnit,
  },
  props: {},
  data () {
    return {
      d_websiteUnitData: '',
      d_frontendWebsiteClientID: '',
      d_pageInfoData: '',
      d_version: version,
    };
  },
  created: function () {
    this.f_getPageData();
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_preparePageInfoData: function (page_data_wdmr) {
      let page_data = {};
      let status = '';
      let show_in_menu = '';
      let menu_caption = '';
      let click_type = '';
      let route_path = '';
      let page_top_caption = '';
      let is_dashboard = '';
      let page_info = '';
      let website_unit_no = '';
      let parent_website_unit_no = '';
      try {
        status = page_data_wdmr['data']['0']['0']['val']['value'];
      } catch (err) {}
      try {
        show_in_menu = page_data_wdmr['data']['0']['1']['val']['value'];
      } catch (err) {}
      try {
        menu_caption = page_data_wdmr['data']['0']['2']['val'];
      } catch (err) {}
      try {
        click_type = page_data_wdmr['data']['0']['3']['val']['value'];
      } catch (err) {}
      try {
        route_path = page_data_wdmr['data']['0']['4']['val'];
      } catch (err) {}
      try {
        page_top_caption = page_data_wdmr['data']['0']['5']['val'];
      } catch (err) {}
      try {
        is_dashboard = page_data_wdmr['data']['0']['6']['val']['value'];
      } catch (err) {}
      try {
        website_unit_no = page_data_wdmr['data']['0']['23']['val'];
      } catch (err) {}
      try {
        parent_website_unit_no = page_data_wdmr['data']['0']['24']['val'];
      } catch (err) {}
      if (status) {
        page_data['status'] = status;
      }
      if (show_in_menu) {
        page_data['show_in_menu'] = show_in_menu;
      }
      if (menu_caption) {
        page_data['menu_caption'] = menu_caption;
      }
      if (click_type) {
        page_data['click_type'] = click_type;
      }
      if (route_path) {
        page_data['route_path'] = route_path;
      }
      if (page_top_caption) {
        page_data['page_top_caption'] = page_top_caption;
      }
      if (is_dashboard) {
        page_data['is_dashboard'] = is_dashboard;
      }
      if (website_unit_no) {
        page_data['website_unit_no'] = website_unit_no;
      }
      if (parent_website_unit_no) {
        page_data['parent_website_unit_no'] = parent_website_unit_no;
      }
      if (page_data_wdmr['data']['0']['7'] && page_data_wdmr['data']['0']['7']['list'] && page_data_wdmr['data']['0']['7']['list'].length > 0) {
        for (let page_info_index in page_data_wdmr['data']['0']['7']['list']) {
          let page_info_item = page_data_wdmr['data']['0']['7']['list'][page_info_index];
          if (page_info_item) {
            if (!page_data.list) {
              page_data.list = [];
            }
            let new_page_info_item = {
              'caption': '',
              'list': [],
            };
            let page_info_caption = '';
            try {
              page_info_caption = page_info_item['8']['val'];
            } catch (err) {}
            if (page_info_caption) {
              new_page_info_item['caption'] = page_info_caption;
            }
            if (page_info_item['9'] && page_info_item['9']['list'] && page_info_item['9']['list'].length > 0) {
              for (let page_info_sub_2_index in page_info_item['9']['list']) {
                let page_info_sub_2_item = page_info_item['9']['list'][page_info_sub_2_index];
                let new_page_info_sub2_item = {
                  'label': '',
                  'button_name': '',
                  'code': '',
                  'link': '',
                  'method_name': '',
                  'trigger_point': '',
                  'image_name': '',
                  'mail': '',
                  'iframe': '',
                  'iframe': '',
                  'img_link': '',
                  'machine_learning_id': '',
                  'talk_data_wanalyzer_project_id': '',
                  'image_base_64': '',
                };
                let page_info_detail_caption = '';
                try {
                  page_info_detail_caption = page_info_sub_2_item['10']['val'];
                } catch (err) {}
                if (page_info_detail_caption) {
                  new_page_info_sub2_item['label'] = page_info_detail_caption;
                }

                let button_name = '';
                try {
                  button_name = page_info_sub_2_item['11']['val'];
                } catch (err) {}
                if (button_name) {
                  new_page_info_sub2_item['button_name'] = button_name;
                }

                let code = '';
                try {
                  code = page_info_sub_2_item['12']['val'];
                } catch (err) {}
                if (code) {
                  new_page_info_sub2_item['code'] = code;
                }

                let link = '';
                try {
                  link = page_info_sub_2_item['13']['val'];
                } catch (err) {}
                if (link) {
                  new_page_info_sub2_item['link'] = link;
                }

                let method_name = '';
                try {
                  method_name = page_info_sub_2_item['14']['val'];
                } catch (err) {}
                if (method_name) {
                  new_page_info_sub2_item['method_name'] = method_name;
                }

                let trigger_point = '';
                try {
                  trigger_point = page_info_sub_2_item['15']['val'];
                } catch (err) {}
                if (trigger_point) {
                  new_page_info_sub2_item['trigger_point'] = trigger_point;
                }

                let image_name = '';
                try {
                  image_name = page_info_sub_2_item['16']['val'];
                } catch (err) {}
                if (image_name) {
                  new_page_info_sub2_item['image_name'] = image_name;
                }

                let mail = '';
                try {
                  mail = page_info_sub_2_item['17']['val'];
                } catch (err) {}
                if (mail) {
                  new_page_info_sub2_item['mail'] = mail;
                }

                let iframe = '';
                try {
                  iframe = page_info_sub_2_item['18']['val'];
                } catch (err) {}
                if (iframe) {
                  new_page_info_sub2_item['iframe'] = iframe;
                }

                let img_link = '';
                try {
                  img_link = page_info_sub_2_item['19']['val'];
                } catch (err) {}
                if (img_link) {
                  new_page_info_sub2_item['img_link'] = img_link;
                }

                let machine_learning_id = '';
                try {
                  machine_learning_id = page_info_sub_2_item['20']['val'];
                } catch (err) {}
                if (machine_learning_id) {
                  new_page_info_sub2_item['machine_learning_id'] = machine_learning_id;
                }

                let talk_data_wanalyzer_project_id = '';
                try {
                  talk_data_wanalyzer_project_id = page_info_sub_2_item['21']['val'];
                } catch (err) {}
                if (talk_data_wanalyzer_project_id) {
                  new_page_info_sub2_item['talk_data_wanalyzer_project_id'] = talk_data_wanalyzer_project_id;
                }

                let image_base_64 = '';
                try {
                  image_base_64 = page_info_sub_2_item['22']['val'];
                } catch (err) {}
                if (image_base_64) {
                  new_page_info_sub2_item['image_base_64'] = image_base_64;
                }
                new_page_info_item['list'].push(new_page_info_sub2_item);
              }
            }
            page_data.list.push(new_page_info_item);
          }
        }
      }
      return page_data;
    },
    f_getPageData: function () {
      // console.log('this.$route : ', this.$route);
      // this.d_websiteUnitData = '';
      if (this.$route.meta.client_id) {
        this.d_frontendWebsiteClientID = this.$route.meta.client_id;
        let query = 'websiteunit_client_id=' + this.d_frontendWebsiteClientID;
        query += '&data_type=wdmr_mode';
        let data = {};
        FrontendService.frontend_websiteunit_get(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              // this.d_pageInfoData = this.f_preparePageInfoData(resp.data.data);
              this.d_websiteUnitData = '';
              setTimeout(function () {
                this.$forceUpdate();
                this.d_websiteUnitData = resp.data.data;
              }.bind(this), 10)
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      } else {
        for (let i in frontend_websiteunit) {
          let route_name = '';
          try {
            route_name = frontend_websiteunit[i]['data']['0']['25']['val'];
          } catch (err) {}
          if (route_name === this.$route.name) {
            this.d_pageInfoData = this.f_preparePageInfoData(frontend_websiteunit[i]);
            break;
          }
        }
      }
    }
  },
  watch: {
    '$route.name': function () {
      this.f_getPageData();
    }
  }
}

</script>

